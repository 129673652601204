import { useEffect, useState } from 'react';
import Image from 'components/shared/image';
import Button from 'components/shared/button';
import classNames from 'classnames';
import RichText from 'components/shared/richtext';
import useIsMobile from 'hooks/utils/useIsMobile';
import VideoPlayer from 'components/shared/video/video';
import styles from './cta.module.scss';

const Cta = ({ className, style, data, firstElement = false, locale, isHero, isCollection }) => {
  const {
    headline,
    textStyle,
    textPosition,
    textPositionMobile,
    textColor,
    body,
    media,
    name,
    button,
    subtitle,
    fontFamily,
  } = data;
  const [hasItalic, setHasItalic] = useState();
  const isMobile = useIsMobile();

  useEffect(() => {
    setHasItalic(textStyle === 'Italic');
  }, [textStyle]);

  const elemStyle = () => {
    const customStyle = {};
    const position = isMobile ? textPositionMobile : textPosition;

    switch (position) {
      case 'Top':
        customStyle.justifyContent = 'flex-start';
        break;
      case 'Bottom':
        customStyle.justifyContent = 'flex-end';
        break;
      default:
        customStyle.justifyContent = 'center';
        break;
    }

    if (isHero && isMobile) {
      customStyle.paddingBottom = '8rem';
    }

    return customStyle;
  };

  return (
    <section
      id="sectionCta"
      style={style}
      className={classNames(
        className,
        name !== 'CTA - The Best Care For Your Hair' && styles.section,
        name === 'CTA - The Best Care For Your Hair' && styles.brandSection
      )}
    >
      {media?.type === 'Video' && media?.video ? (
        <VideoPlayer
          className={classNames(styles.media)}
          thumbnail={data?.media?.thumbnail}
          source={data.media.video}
          sourceDesktop={data.media.videoDesktop}
          autoPlay
          controls={false}
          forceKeepPlaying
        />
      ) : (
        <Image
          src={media.image}
          layout="fill"
          className={styles.media}
          loading={firstElement ? 'eager' : 'lazy'}
          priority={firstElement}
        />
      )}

      <div
        className={classNames(styles.flex, {
          [styles.pointerEventsNone]: media?.image?.link,
        })}
        style={elemStyle()}
      >
        {subtitle && (
          <span
            className={classNames(styles.subtitle, {
              [styles.subtitleFreight]: !isCollection && fontFamily === 'Freight Big',
              [styles.subtitleFreightSmall]: isCollection && fontFamily === 'Freight Big',
            })}
            style={{ color: textColor || 'white' }}
          >
            {subtitle}
          </span>
        )}
        <h1
          className={classNames({
            [styles.headline]: name !== 'CTA - The Best Care For Your Hair',
            [styles.brandHeadline]: name === 'CTA - The Best Care For Your Hair',
            [styles.headlineFreight]: !isCollection && fontFamily === 'Freight Big',
            [styles.headlineFreightSmall]: isCollection && fontFamily === 'Freight Big',
          })}
          style={{
            color: textColor || 'white',
          }}
        >
          {headline}
        </h1>
        {body && (
          <RichText
            className={classNames(styles.body, {
              [styles.bodyFreight]: fontFamily === 'Freight Big',
            })}
            text={body.json}
          />
        )}
        {button && (
          <Button
            locale={locale}
            button={button}
            className={classNames(
              styles.ctaButton,
              name === 'CTA - The Best Care For Your Hair' && styles.brandButton
            )}
          />
        )}
      </div>
    </section>
  );
};

export default Cta;
